.property-container{
    gap: 2rem;
    position: relative;
}

.property-container .like{
    position: absolute;
    top: 3rem;right: 3rem;
    cursor: pointer;
}


.property-container>img{
    align-self: center;
    max-height: 35rem;
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
}

.property-details{
    width: 100%;
    gap: 2rem;
    justify-content: space-between;
}

.property-details>div{
    flex: 1;
    gap: 1.5rem;
}

.property-details .left .head{
    justify-content: space-between;
    width: 100%;
}

.facilities{
    gap: 1rem;
    font-size: 0.9rem;
    padding: 1rem;
}

.facility{
    gap: 0.5rem;
}

.property-details .button{
    width: 100%;
    margin-top: 1rem;
}

.property-details .button:hover{
    transform: scale(1.05);
}


@media (max-width: 700px) {
    .property-container>div{
        flex-direction: column;
    }
    .head{
        flex-direction: column;
    }
    .facilities{
        flex-direction: column;
        align-items: flex-start;
    }
    .property-details>div{
        width: 100%;
    }


}

.solaGit,
.sagaGit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5); /* Düğme arkaplan rengini ve şeffaflığı ayarlayabilirsiniz */
  color: #fff; /* Düğme rengini ayarlayabilirsiniz */
  border: none;
  cursor: pointer;
  padding: 10px 15px; /* Düğme boyutunu ayarlayabilirsiniz */
}

.solaGit {
  left: 10px;
}

.sagaGit {
  right: 10px;
}

.kucuk-resimler {
  display: flex;
  width: 50%;
  
}

.kucuk-resimler img {
  cursor: pointer;
  margin: 5px;
  max-width: 100px;
}
.buyuk-resim{
    position: relative;
}



 
@media (max-width: 768px){
    .alt-div{
        display: flex;
  flex-direction: column;
    }
.alt-div  .resim-slider{
        /* width:100%;
        height: 20rem;
        margin: 2rem;
        display: inline-block;
        border:20px solid red; */
        
    } 

.kucuk-resimler{
    display:none;
} 

.sütun-2-satır{
    border-bottom: 1px dashed gray;
  
    padding: 2px;
    padding-left: 5px;
    font-size: 0.8rem;
   
  };
  .tabloStili{
    width:200px;
    font-size: 0.5rem;
   
  }  
.en .baslık{
    font-size: 1rem;
}

}



.tabloStili{
    border-collapse: collapse;
    width: 400px;

    @media (max-width: 768px){
        width: 300px;
    }

    @media (max-width: 1000px){
        width: 100%;

    }   


    padding-left: 0;
    margin-left: 0;
    padding-top: 0;
    margin-top:0;
  };


 .resimkaydir {
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
    scroll-margin-left: 100px; /* İlk resmin genişliği kadar boşluk bırakın */
  }

  .hücreStili {
    borderBottom: 1px dotted gray;
    padding-top: 3%;
   
  }

  .paddingIlan{

    @media (max-width: 768px){
        padding-left: 10px;
    }
  
  }

  .paddingYer{

    @media (max-width: 768px){
        padding-left: 20px;
    }
  
  }