@import '../../styles/contants.scss';



.wrapper {
    position: relative;
    z-index: 99;
    opacity: 1;
    transform: none;
    position: sticky;
    top: 0px;
    transition: all 300ms ease;

   
 

    .container {

      
        position: relative;
        justify-content: space-between;
        font-weight: 500;
        
        .name {
            
            display:flex;
            width:100px;
            height:41px;


            @media (max-device-width: $sm),
            (max-width: $md) {
                display:none;
             }
        }

        .menu {

            @media (max-device-width: $sm),
            (max-width: $md) {
              
                position: absolute;
                list-style: none;
                gap: 2rem;
                font-weight: 500;
                flex-direction: column;
                right: 0;
                top: 3rem;
                height: calc(80vh - 8.5rem);
                width: 50%;
                min-width: 15rem;
                background: white;
                padding: 2rem;
                display: none;
                border-radius: 10px;
                transition: all 200ms ease;
                align-items: flex-start;
                padding: 3rem;
                justify-content: flex-start;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
            }

            text-transform: uppercase;
            font-size: 1.05rem;
            list-style: none;
            gap: 1.5rem;
            cursor: pointer;

            li {
                a {
                    color: inherit;
                    text-decoration: none;
                }

                &:hover {
                    color: $secondary;
                }
            }

            .phone {
                gap: 1.2rem;
                flex-wrap: wrap;

                svg {
                    color: $secondary;
                    background-color: white;
                    padding: 10px;
                    border-radius: 999px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                }
            }
        }

        

        .menuIcon {

            @media (max-width: $sm),
            (max-width: $md) {
                display: block;
            }

            display: none;
        }
    }
    @media (max-device-width: 798px) {
        .sidebar {
            /* Sidebar için stil tanımlamalarını burada yapabilirsiniz */
            background-color: blue; /* Mavi arkaplan rengi */
            width: 300px; /* Genişlik */
            height: 100vh; /* Ekran yüksekliği kadar uzunluk */
            position: fixed; /* Sabit pozisyon */
            top: 0;
            right: -300px; /* Başlangıçta sağdan gizli */
            transition: right 0.3s ease; /* Açılış/kapanış animasyonu */
            z-index: 1000; /* Diğer içeriklerin üstünde olması için z-indeks */
            padding: 20px; /* İçerik içinde boşluk */
            overflow-y: auto; /* Sayfa boyunca kaydırma */
          }
          
          /* Bağlantıları özelleştirmek için örnek stil */
          .sidebar ul {
            list-style: none;
            padding: 0;
          }
          
          .sidebar li {
            margin-bottom: 10px;
          }
          
          .sidebar a {
            color: white;
            text-decoration: none;
          }
          
          .sidebar a:hover {
            color: gray;
          }
          
    }
}