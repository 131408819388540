@import '../../styles/contants.scss';

.wrapper {
    .container {
        flex-direction: column;
        gap: 2rem;

        .experiences {
            width: 100%;
            flex-direction: column;
            gap: 1.4rem;
            position: relative;

            .exp {
                width: 100%;
                justify-content: space-between;
                line-height: 40px;
                align-items: flex-start;

                >div {
                    flex: 1;
                }

                .role {
                    margin-left: 2rem;
                    
                }
            }

            .progressbar {
                @media screen and (max-width: 767px) {
                    padding-top: 90px;
                  }
                padding-top: 40px;
                position: absolute;
                height: 110%;
                display: flex;
                flex-direction: column;
                gap: 1.4rem;
                left: 45%;

                .line {
                    position: absolute;
                    height: 92%;
                    width: 2px;
                    background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
                    background-position: right;
                    background-size: 2px 30px;
                    background-repeat: repeat-y;
                    left: 48%;
                    z-index: 1;
                    border-radius: 5px;
                }

                >div {
                    flex: 1;
                    padding-top: .8rem;
                    z-index: 2;

                    .circle {
                        border-radius: 50%;
                        background: black;
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                }
            }
        }

    }
}